<template>
  <div class="req-temp-form">
    <form @submit.prevent="createTemplate" enctype="multipart/form-data">
      <vs-row id="profile-page">
        <!-- My Details -->
        <vx-card class="accordin-card">
          <div class="vx-card__body bsv p-0">
            <vs-collapse accordion>
              <vs-collapse-item open ref="templateCollapse">
                <div slot="header">
                  <h3 class="text-left card-title font-normal">
                    Page details <span class="text-2xl mid-blue">*</span>
                  </h3>
                </div>
                <vs-row>
                  <vs-col vs-w="6" vs-xs="12">
                    <p><label>Page name</label></p>
                    <vs-input type="text" v-model="templateName" v-validate="'required|max:30'" name="templateName" class="lg:w-2/4 md:w2/4 sm:w-1/2 w-full"
                      id="templateName"/>
                    <vs-col vs-type="flex" vs-align="center" vs-w="12">
                      <span class="text-danger text-sm" v-show="errors.has('templateName')">{{ errors.first("templateName") }}</span>
                    </vs-col>
                  </vs-col>
                  <vs-col vs-w="4" v-if="editFlag">
                    <label>Page ID</label>
                    <p class="mt-2">{{ templateId }}</p>
                  </vs-col>
                </vs-row>
              </vs-collapse-item>
            </vs-collapse>
          </div>
        </vx-card>

        <vx-card class="accordin-card mt-5">
          <vs-collapse accordion>
            <vs-collapse-item open ref="linkedCollapse">
              <div slot="header">
                <h3 class="text-left card-title font-normal">
                  Linked Account <span class="text-2xl mid-blue">*</span>
                </h3>
              </div>
              <p class="mb-4"> Nominate the bank account to which payments collected via this payment request will be directed.</p>

              <vs-row>
                <vs-col vs-type="flex" vs-align="center">
                  <vs-select v-model="selectedAccount" name="linkedAccount" id="linkedAccount" class="lg:w-1/4 md:w1/4 sm:w-1/2 w-full" v-validate="'required'" @change="changeConnector" :placeholder="'Select account'">
                    <vs-select-item :key="index" :value="item.bankId" :text="item.displayName ? item.displayName : item.accountName" v-for="(item, index) in bankList"/>
                  </vs-select>
                </vs-col>
                <vs-col vs-type="flex" vs-align="center" vs-w="12">
                  <span class="text-danger text-sm" v-show="errors.has('linkedAccount')">{{ errors.first("linkedAccount") }}</span>
                </vs-col>
              </vs-row>
            </vs-collapse-item>
          </vs-collapse>
        </vx-card>

        <vx-card class="accordin-card mt-5">
          <vs-collapse accordion>
            <vs-collapse-item ref="headerCollapse">
              <div slot="header">
                <h3 class="text-left card-title font-normal">Branding</h3>
              </div>

              <p>This information will appear on this hosted payment page. Customers will see it when making a payment.</p>
              <page-header @checkToggle="partnerToggle" v-if="headerBind" :headerData="header" :logos="logoLibrary" @changeHeader="headerChange" @checkDirty="checkDirty"></page-header>
            </vs-collapse-item>
          </vs-collapse>
        </vx-card>

        <CustomTextBlock :customTextBlockData="customTextBlockData" @checkDirty="checkDirty"></CustomTextBlock>

        <vx-card class="accordin-card mt-5">
          <vs-collapse accordion>
            <vs-collapse-item ref="customCollapse">
              <div slot="header">
                <h3 class="text-left card-title font-normal">Custom fields</h3>
              </div>

              <p>You can create custom form fields to collect information related to this payment request.</p>
              <custom-fields v-if="customFieldBind" v-on:savedCustomField="pushToCustomFields($event)" :customFieldValue="customFieldList"></custom-fields>
            </vs-collapse-item>
          </vs-collapse>
        </vx-card>

        <notification v-if="notifyBind" :notification="notifications" :transactionNotificationsCheckbox="transactionNotificationsCheckbox" @changeNotification="notifyChange" @checkDirty="checkDirty" :isSubmitted="isSubmitted"></notification>

        <sender-information v-if="senderBind" :senderData="senderDetails" @changeSender="changeSender" @validateForm="formValidation" @checkDirty="checkDirty"></sender-information>
      </vs-row>
      <leave-popup :popup-active="popupActive" @handleClick="popActions"></leave-popup>

      <div class="flex justify-end mt-5" v-if="errors.items.length > 0">
        <!--- validation is displayed here, userform is name of the form-->
        <pop-up :errorsList="errors" form-name="RequestTemplateForm"></pop-up>
      </div>
      <vs-row class="mt-16">
        <div class="actions text-right">
          <vs-button v-if="editFlag" v-round size="large" class="only-border-btn" type="border" @click="popupDelActive = true">Delete page</vs-button>
          <vs-button size="large" class="mx-8" v-round :disabled="!validateForm || errors.items.length > 0" @click="createTemplate">{{ saveTemplate }}</vs-button>
          <a @click="onCancel" class="edit-profile mid-blue" v-round>Cancel</a>
        </div>
      </vs-row>
    </form>
    <delete-popup :popup-active="popupDelActive" :warningContent="warningContent" :additionalMsg="additionalMsg" :deleteWhat="deleteWhat" :buttonText="buttonText"
      :found="warningFound" @handleDeleteClick="delActions"></delete-popup>
  </div>
</template>

<script>
import CustomTextBlock from "./CustomTextBlock.vue";
import { mapActions, mapState } from "vuex";
import { Validator } from "vee-validate";
import LeavePopup from "@/views/components/LeavePopup";
import PopUp from "@/views/components/ValidationPopup.vue";
import PageHeader from "./Header.vue";
import SenderInformation from "./SenderInformation.vue";
import GroupAccess from "./GroupAccess.vue";
import Notification from "./Notification.vue";
import CustomFields from "./customFields.vue";
import DeletePopup from "@/views/components/DeletePopup";
import { leavePopUpMixin } from "@/mixins/leave-pop-up";

const dictionary = {
  en: {
    attributes: {
      templateName: "template name",
      linkedAccount: "linked account",
    },
  },
};
Validator.localize(dictionary);
export default {
  mixins: [leavePopUpMixin],
  components: {
    PopUp,
    LeavePopup,
    PageHeader,
    CustomTextBlock,
    SenderInformation,
    GroupAccess,
    Notification,
    CustomFields,
    DeletePopup,
  },
  data() {
    return {
      popupActive: false, // determines if the leave popup is to be shown
      showValidationPopUp: false, // determines if the leave popup is to be shown
      nextObj: "", // determines the next route,
      isSaved: false,
      templateName: "",
      templateId: "",
      linkedAccount: {},
      selectedAccount: "",
      connectorList: [],
      displayName: "",
      defaultHeader: {},
      header: {
        companyName: "",
      },
      logoLibrary: [],
      merchantId: "",
      headerBind: false,
      imageUrl: `${process.env.VUE_APP_API_URL}uploads/merchantlogo/`,
      isSubmitted: false,
      customFieldList: [],
      customFieldBind: false,
      // custom text block
      customTextBlockData: {
        heading: "",
        body: "",
      },
      // Payment options *
      paymentOptionData: {
        requestType: "one-off",
        requestOptions: ["pay-now"],
      },
      paymentDataDefault: JSON.parse(localStorage.getItem("user")),
      senderBind: false,

      defaultSender: {},
      senderDetails: {},
      pageSender: {},
      groupBind: false,
      paymentBind: false,
      merchantGroups: [],
      groups: [],
      selectedGroupData: [],
      notifyBind: false,
      notifications: {
        requestSenderEmail: true,
        txNotification: [],
      },
      transactionNotificationsCheckbox: {
        forEmail: "",
        forDashboard: "",
      },
      pageNotifications: {},
      pageTrNotify: {
        forEmail: "",
        forDashboard: "",
      },
      id: "",
      editHeader: {},
      saveTemplate: "Create hosted page",
      defaultUserArray: [],
      addUserArray: [],
      removeUserArray: [],
      removeGroupArray: [],
      editFlag: false,
      warningContent: "You will need to update any references to this hosted payment page.",
      additionalMsg: "You will still be able to see any existing transactions generated throught this page in your Payment Activity.",
      deleteWhat: `hosted payment page`,
      buttonText: `Delete`,
      popupDelActive: false,
      warningFound: true,
      childFormDirty: false,
      bankList: [],
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
    user() {
      return this.$store.state.AppActiveUser;
    },
    partnerId() {
      return this.user.userType === "admin" ? this.user._id : this.user.partnerId;
    },
    leavePage() {
      return this.popupAnswer;
    },
  },
  created() {
    this.id = this.$route.params.id;
    if (this.id) {
      this.saveTemplate = "Update hosted page";
      this.editFlag = true;
    }
  },
  watch: {
    "errors.items"(val) {
      if (this.errors.items.length > 0) {
        this.$refs.templateCollapse.maxHeight = "none !important";
        this.$refs.linkedCollapse.maxHeight = "none !important";
        this.$refs.headerCollapse.maxHeight = "none !important";

        if (this.$refs.customCollapse) {
          this.$refs.customCollapse.maxHeight = "none !important";
        }
      }
    },
    isFormDirty(latest, previous) {
      if (latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    },

    isSaved(latest, previous) {
      if (!latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    },

    childFormDirty(latest, previous) {
      if (latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    }
  },
  methods: {
    ...mapActions("connector", ["fetchConnectorById"]),
    ...mapActions("bank", ["fetchBanksAccountByPartnerId"]),
    ...mapActions("paymentRequestTemplate", [
      "createRequestTemplate",
      "fetchRequestTemplateByIdOnMerchant",
      "updateRequestTemplateById",
    ]),
    ...mapActions("logo", ["fetchLogosByMerchantId"]),
    ...mapActions("merchantGlobalSetting", [
      "fetchSettingsByMerchantId",
      "fetchNotification",
    ]),
    ...mapActions("merchantGroup", ["fetchMerchantGroupByMerchantId"]),
    async getMerchantGroups() {
      await this.fetchMerchantGroupByMerchantId(this.partnerId)
        .then((res) => {
          this.merchantGroups = res.data.data.docs;
          this.merchantGroups = this.merchantGroups.map((element) => ({
            ...element,
            viewOnly: false,
            viewAndRefund: false,
            useThemeViewOnly: false,
            useThemeViewAndRefund: false,
            showViewAndRefundMsg: false,
            showViewOnlyMsg: false,
          }));
        })
        .catch((err) => {});
    },
    async getConnectorsList(partnerId) {
      await this.fetchBanksAccountByPartnerId(partnerId)
        .then((result) => {
          let banksList = result.data.data;
          this.defaultLoadedConnector = result.data.data;
          this.bankList = banksList
          .filter(item => item.isUsedForSettlement == true)
          .map((item) => {
            return {
              bankId: item._id,
              displayName: item.displayName ? item.displayName : item.accountName,
            };
          });
        })
        .catch((err) => {});
    },

    async fetchTemplateDetails() {
      await this.fetchRequestTemplateByIdOnMerchant(this.id)
        .then(async (result) => {
          this.templateName = result.data.data[0].templateName;
          this.templateId = result.data.data[0].requestTemplateId;
          this.selectedAccount = result.data.data[0].linkedAccount.bankId ? result.data.data[0].linkedAccount.bankId : ``;
          this.linkedAccount = result.data.data[0].linkedAccount;
          if (!this.linkedAccount.bankId && this.linkedAccount.connectorId) {
            const connector = await this.fetchConnectorById(this.linkedAccount.connectorId);
            this.selectedAccount = connector.data.data.bankAccountId;
            this.linkedAccount.bankId = connector.data.data.bankAccountId;
          }
          let headerFlag = result.data.data[0].header.isDefaultHeader;
          this.editHeader = {
            isDefaultHeader: result.data.data[0].header.isDefaultHeader,
            abn: headerFlag ? this.defaultHeader.abn : result.data.data[0].header.abn,
            companyName: headerFlag ? this.defaultHeader.companyName : result.data.data[0].header.companyName,
            companyEmail: headerFlag ? this.defaultHeader.companyEmail : result.data.data[0].header.companyEmail,
            address: headerFlag ? this.defaultHeader.address : result.data.data[0].header.address,
            companyLogo: headerFlag
              ? this.defaultHeader.companyLogo
                ? this.defaultHeader.companyLogo
                : undefined
              : result.data.data[0].header.companyLogo
                ? result.data.data[0].header.companyLogo._id
                : undefined,
            image: headerFlag
              ? this.defaultHeader.image
                ? this.defaultHeader.image
                : undefined
              : result.data.data[0].header.companyLogo
                ? result.data.data[0].header.companyLogo.logoImage
                : undefined,
            partnerLogo: headerFlag
              ? this.defaultHeader.partnerLogo
                ? this.defaultHeader.partnerLogo
                : undefined
              : result.data.data[0].header.partnerLogo
                ? result.data.data[0].header.partnerLogo._id
                : undefined,
            partnerimage: headerFlag
              ? this.defaultHeader.partnerimage
                ? this.defaultHeader.partnerimage
                : undefined
              : result.data.data[0].header.partnerLogo
                ? result.data.data[0].header.partnerLogo.logoImage
                : undefined,
            partnerLogoEnabled: headerFlag ? this.defaultHeader.partnerLogoEnabled : result.data.data[0].header.partnerLogoEnabled,
            phone: headerFlag ? this.defaultHeader.phone : result.data.data[0].header.phone,
            website: headerFlag ? this.defaultHeader.website : result.data.data[0].header.website,
          };
          this.header = { ...this.editHeader };
          this.customTextBlockData = result.data.data[0].customText;

          this.pageSender = result.data.data[0].senderDetails;
          this.senderDetails = { ...this.pageSender };
          this.pageNotifications = result.data.data[0].notifications;
          this.notifications = { ...this.pageNotifications };
          this.pageTrNotify.forEmail = this.beChecked(result.data.data[0].notifications.txNotification, "forEmail");
          this.pageTrNotify.forDashboard = this.beChecked(result.data.data[0].notifications.txNotification, "forDashboard");
          this.transactionNotificationsCheckbox = { ...this.pageTrNotify };

          this.selectedGroupData =
            result.data.data[0].userGroupAccessHosted.map((element) => {
              let objIndex = element.permissionsOnHostedPages.findIndex(
                (obj) => obj.hostedPageId.toString() == this.id
              );
              if (objIndex >= 0) {
                return {
                  _id: element._id,
                  groupName: element.groupName,
                  viewOnly: element.permissionsOnHostedPages[objIndex].viewOnly,
                  viewAndRefund:
                    element.permissionsOnHostedPages[objIndex].viewAndRefund,
                  useThemeViewOnly:
                    element.permissionsOnHostedPages[objIndex].viewOnly,
                  useThemeViewAndRefund:
                    element.permissionsOnHostedPages[objIndex].viewAndRefund,
                  showViewOnlyMsg:
                    element.permissionsOnHostedPages[objIndex].viewOnly,
                  showViewAndRefundMsg:
                    element.permissionsOnHostedPages[objIndex].viewAndRefund,
                  users: element.users,
                };
              }
            });
          this.groups = [...this.selectedGroupData];
          var selectgrp = [];
          this.merchantGroups.forEach((element) => {
            this.selectedGroupData.forEach((grdata) => {
              if (grdata._id === element._id) {
                selectgrp.push(grdata);
              }
            });
            const found = selectgrp.some((el) => el._id === element._id);
            if (!found) selectgrp.push(element);
          });
          this.merchantGroups = [...selectgrp];
          this.paymentOptionData = result.data.data[0].paymentOptions[0];
          this.customFieldList = result.data.data[0].customFields;
          this.groups.forEach((group) => {
            group.users.forEach((user) => {
              if (!this.defaultUserArray.includes(user.toString())) {
                this.defaultUserArray.push(user.toString());
              }
            });
          });
        })
        .catch((ex) => {
          this.$vs.notify({
            title: "Hosted payment page",
            text: ex.response.status === 404 ? 'Hosted payment page not found' : ex.response.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right"
          });

          if(ex.response.status === 404) {
            this.$router.push({ name: "hosted-page" });
          }
        });
    },
    pushToCustomFields(customFieldList) {
      this.customFieldList = customFieldList;
      if(this.$refs.customCollapse)
        this.$refs.customCollapse.maxHeight = "none !important";
      this.childFormDirty = true;
    },
    async createTemplate() {
      this.isSubmitted = true;
      const valid = await this.$validator.validate();
      if (this.errors.items.length > 0) {
        this.isSubmitted = false;
        return false;
      }
      if (!valid) {
        this.$refs.templateCollapse.maxHeight = "none !important";
        this.$refs.linkedCollapse.maxHeight = "none !important";
        return false;
      } else {
        const userGroupAccess = this.groups.map((element) => {
          return {
            groupId: element._id,
            groupName: element.groupName,
            viewOnly: element.viewOnly,
            viewAndRefund: element.viewAndRefund,
          };
        });

        this.groups.forEach((group) => {
          group.users.forEach((user) => {
            if (!this.addUserArray.includes(user.toString())) {
              this.addUserArray.push(user.toString());
            }
          });
        });
        let addUser = this.addUserArray.filter(
          (user) => !this.defaultUserArray.includes(user)
        );
        let removeUser = this.defaultUserArray.filter(
          (user) => !this.addUserArray.includes(user)
        );
        this.addUserArray = addUser;
        this.removeUserArray = removeUser;
        this.removeGroupArray = this.selectedGroupData.filter(
          (group) => !this.groups.includes(group)
        );
        const formData = new FormData();
        if (this.id) {
          if (this.user && this.user.adminId && this.user.loginBy) {
            formData.append("editedByAdmin", this.user.adminId);
          }
          formData.append("templateId", this.id);
        }
        if (!this.id) {
          if (this.user && this.user.adminId && this.user.loginBy) {
            formData.append("createdByAdmin", this.user.adminId);
          }
        }
        formData.append("merchantId", this.partnerId);
        formData.append("partnerId", this.merchantId);
        formData.append("templateName", this.templateName);
        formData.append("linkedAccount", JSON.stringify(this.linkedAccount));
        formData.append("header", JSON.stringify(this.header));
        formData.append("companyLogo", this.header.companyLogo);
        formData.append("partnerLogo", this.header.partnerLogo);

        // custom text block
        formData.append("customText", JSON.stringify(this.customTextBlockData));

        // payment options
        formData.append(
          "paymentOptions",
          JSON.stringify(this.paymentOptionData)
        );

        formData.append("senderDetails", JSON.stringify(this.senderDetails));
        formData.append("userGroupAccess", JSON.stringify(userGroupAccess));
        formData.append("notifications", JSON.stringify(this.notifications));
        formData.append("customFields", JSON.stringify(this.customFieldList));
        formData.append("requestType", "hostedpage");
        formData.append("addUserAccess", JSON.stringify(this.addUserArray));
        formData.append("removeUserAccess", JSON.stringify(this.removeUserArray));
        formData.append("removeGroupArray", JSON.stringify(this.removeGroupArray));
        formData.append("templateType", "simple");

        let obj = {
          data: formData,
          config: {
            header: {
              "Content-Type": "multipart/form-data",
            },
          },
        };

        this.$vs.loading();
        this.createRequestTemplate(obj)
          .then((result) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Hosted page",
              text: "Hosted page created successfully",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            localStorage.removeItem("prNotification");
            localStorage.removeItem("txNotification");
            localStorage.removeItem("trCheckbox");
            localStorage.removeItem("prCheckbox");
            this.isSaved = true;
            this.$router.push({ name: "hosted-page" });
          })
          .catch((ex) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Hosted page",
              text: ex.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
      }
    },
    onChangeCustomTextBlock(value) {
      this.customTextBlockData.heading = value.heading;
      this.customTextBlockData.body = value.body;
    },
    getLogos() {
      this.fetchLogosByMerchantId(this.merchantId).then((res) => {
        this.logoLibrary = res.data.data;
      });
    },
    async getNotifications() {
      await this.fetchNotification()
        .then(async (res) => {
          if (res.data.data.prNotification && res.data.data.txNotification) {
            this.notifications.requestSenderEmail = true;
            this.notifications.txNotification = res.data.data.txNotification;
            this.transactionNotificationsCheckbox.forEmail = this.beChecked(
              res.data.data.txNotification,
              "forEmail"
            );
            this.transactionNotificationsCheckbox.forDashboard = this.beChecked(
              res.data.data.txNotification,
              "forDashboard"
            );
            localStorage.setItem(
              "txNotification",
              JSON.stringify(res.data.data.txNotification)
            );
            localStorage.setItem(
              "trCheckbox",
              JSON.stringify(this.transactionNotificationsCheckbox)
            );
          }
        })
        .catch((err) => {

        });
    },
    async getGlobalSetting() {
      await this.fetchSettingsByMerchantId()
        .then(async (res) => {
          if (res.data.data.companyHeader) {
            this.header = res.data.data.companyHeader;
            this.header.isDefaultHeader = true;
            this.header.partnerLogoEnabled =  this.header.partnerLogoEnabled ? this.header.partnerLogoEnabled : false;
            this.header.image = this.header.companyLogo
              ? this.header.companyLogo.logoImage
              : undefined;
            this.header.companyLogo = this.header.companyLogo
              ? this.header.companyLogo._id
              : undefined;
            this.header.partnerimage = this.header.partnerLogo
              ? this.header.partnerLogo.logoImage
              : undefined;
            this.header.partnerLogo = this.header.partnerLogo ? this.header.partnerLogo._id : undefined;
            this.defaultHeader = this.header;
          } else {
            this.header.isDefaultHeader = true;
            this.defaultHeader.isDefaultHeader = true;
          }

          if (res.data.data.hostedPageCommunication) {
            this.defaultSender.allowOverride =
              res.data.data.hostedPageCommunication.allowOverride || true;
            this.defaultSender.senderType = "1";
            this.defaultSender.fullname =
              res.data.data.hostedPageCommunication.fullname;
            this.defaultSender.senderEmail =
              res.data.data.hostedPageCommunication.senderEmail;
            this.defaultSender.senderPhone =
              res.data.data.hostedPageCommunication.senderPhone;
            this.senderDetails = res.data.data.hostedPageCommunication;
            this.senderDetails.senderType = "1";
          } else {
            this.defaultSender.senderType = "1";
            this.senderDetails.senderType = "1";
          }

          if (this.id) {
            await this.fetchTemplateDetails();
          }
        })
        .catch((err) => {

          this.headerBind = true;
          this.senderBind = true;
          this.notifyBind = true;
          this.groupBind = true;
          this.customFieldBind = true;
        });
      this.headerBind = true;
      this.senderBind = true;
      this.notifyBind = true;
      this.groupBind = true;
      this.customFieldBind = true;
    },
    beChecked(value, field) {
      return value.every((el) => el[field]);
    },
    headerChange(e) {
      if (e) {
        this.header = { ...this.defaultHeader };
      } else {
        if (this.editHeader && this.editHeader.isDefaultHeader == false) {
          this.header = { ...this.editHeader };
        } else {
          this.header = {};
          this.header.isDefaultHeader = e;
        }
      }
      this.partnerToggle();
    },
    partnerToggle() {
      this.childFormDirty = true;
      this.$refs.headerCollapse.maxHeight = "none !important";
    },
    changeSender(e) {
      this.senderDetails.senderType = e;
      if (e == "1") {
        this.senderDetails.allowOverride = this.defaultSender.allowOverride;
        this.senderDetails.fullname = this.defaultSender.fullname;
        this.senderDetails.senderPhone = this.defaultSender.senderPhone;
        this.senderDetails.senderEmail = this.defaultSender.senderEmail;
      } else if (e == "2") {
        if (this.pageSender && this.pageSender.senderType == "2") {
          this.senderDetails = { ...this.pageSender };
        } else {
          this.senderDetails.allowOverride = true;
          this.senderDetails.fullname = "";
          this.senderDetails.senderPhone = "";
          this.senderDetails.senderEmail = "";
        }
      } else if (e == "3") {
        this.senderDetails.allowOverride = true;
        this.senderDetails.fullname = JSON.parse(
          localStorage.user
        ).emailSenderName;
        this.senderDetails.senderPhone = JSON.parse(
          localStorage.user
        ).phoneNumber;
        this.senderDetails.senderEmail = JSON.parse(localStorage.user).email;
      }
      this.childFormDirty = true;
    },
    formValidation() {
      return !this.errors.any();
    },
    accessGroups(e) {
      this.groups = [];
      this.groups = e;
    },
    notifyChange(e) {
      this.notifications = {};
      if (Object.keys(this.pageNotifications).length > 0) {
        this.notifications = { ...this.pageNotifications };
        this.transactionNotificationsCheckbox = { ...this.pageTrNotify };
      } else {
        this.notifications.txNotification = JSON.parse(
          localStorage.getItem("txNotification")
        );
        this.transactionNotificationsCheckbox = JSON.parse(
          localStorage.getItem("trCheckbox")
        );
      }
      this.notifications.requestSenderEmail = e;
      if (e) {
        this.notifications.emailAddress = "";
      }
      this.childFormDirty = true;
    },

     changeConnector() {
      if (this.bankList.length > 0) {
        let objIndex = this.bankList.findIndex(
          (obj) => obj.bankId === this.selectedAccount
        );
       this.linkedAccount = this.bankList[objIndex];
      }
    },

    onCancel() {
      this.$router.push({ name: "hosted-page" });
    },
    delActions(event) {
      switch (event) {
        case "delete":
          this.popupDelActive = false;
          this.deleteTemplate();
          break;
        case "cancel":
          this.popupDelActive = false;
          break;
        default:
          this.popupDelActive = false;
      }
    },
    async deleteTemplate() {
      let obj = {
        id: this.id,
        payload: { merchantId: this.partnerId, deleted: true },
      };
      this.$vs.loading();
      this.updateRequestTemplateById(obj).then((res) => {
        this.isSaved = true;
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Hosted payment page",
          text: "Hosted payment page deleted successfully",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.onCancel();
      }).catch((err) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Hosted payment page",
          text: err.response.data.message,
          iconPack: "feather",
          icon: "icon-times-circle",
          color: "danger",
        });
      });
    },
    checkDirty(val) {
      this.childFormDirty = val;
    },
  },
  async mounted() {
    this.getMerchantGroups();
    this.getNotifications();
    await this.getGlobalSetting();
    this.merchantId =
      JSON.parse(localStorage.user).userType == "admin"
        ? JSON.parse(localStorage.user).merchantId
        : JSON.parse(localStorage.user).partner.merchantId;
    this.getConnectorsList(this.partnerId);
    this.getLogos();
  },
  beforeRouteLeave(to, from, next) {
    //check here
    if ((this.isFormDirty || this.childFormDirty) && !this.isSaved) {
      this.nextObj = next;
      localStorage.setItem("redirectTo", to.name || undefined);
      this.popupActive = true;
    } else {
      next();
    }
  },
};
</script>