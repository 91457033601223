<template>
  <vx-card class="accordin-card mt-5">
    <vs-collapse accordion>
      <vs-collapse-item ref="cusTextCollapse">
        <div slot="header">
          <h3 class="text-left font-normal card-title">Custom text block</h3>
        </div>

        <p>
          Add any instructions or comments you would like customers to see at
          the top of the payment page before they enter their payment details.
        </p>

        <div class="card-body md:flex mb-10">
          <vs-row class="mt-5 w-full md:w-6/12 lg:w-4/12">
            <vs-col vs-w="10" vs-xs="12">
              <vs-input name="headingText" class="w-full block"
                label="Heading" size="large" v-model="customTextBlockData.heading" />
            </vs-col>
            <vs-col vs-type="flex" vs-align="center" vs-w="12">
              <span size="large" class="text-danger text-sm"
                v-show="errors.has('headingText')">{{ errors.first("headingText") }}</span>
            </vs-col>
          </vs-row>
          <vs-row class="mt-5 w-full md:w-6/12 lg:w-4/12">
            <vs-col vs-w="10" vs-xs="12">
              <label for="" class="vs-input--label block mb-2">Body</label>
              <vs-textarea
              name="bodyText" v-model="customTextBlockData.body"
              counter="250"
                maxlength="250"
                id="custom-text-block"
                v-validate="'min:3|max:250'"
                @input="auto_grow('custom-text-block')"
              ></vs-textarea>
            </vs-col>

            <vs-col vs-type="flex" vs-align="center" vs-w="12">
              <!-- <span
                        class="text-danger text-sm"
                        v-show="errors.has('bodyText')"
                        >{{ errors.first("bodyText") }}</span>
                        -->
            </vs-col>
          </vs-row>

        </div>
        <!-- {{isSubmitted}} -->
        <!-- {{errors}} -->
        <!-- {{customTextBlockData}} -->
      </vs-collapse-item>
    </vs-collapse>
  </vx-card>
</template>

<script>
  import {
    Validator
  } from "vee-validate";
  const dictionary = {
    en: {
      attributes: {
        headingText: "heading",
        bodyText: "body"
      },
    },
  };
  Validator.localize(dictionary);
  export default {
    props: ["customTextBlockData", "isSubmitted"],
    watch: {
      "customTextBlockData.body": function(newVal,oldVal){
        if (newVal !== oldVal) {
          this.$emit("checkDirty",true)
        }
      },
      "customTextBlockData.heading": function(newVal,oldVal){
        if (newVal !== oldVal) {
          this.$emit("checkDirty",true)
        }
      },
    },
    methods: {
      auto_grow(elem) {
        let element = document.getElementById(elem);
        element.style.height = "5px";
        element.style.height = (element.scrollHeight)+"px";
        this.$refs.cusTextCollapse.maxHeight = "none !important";
      }
    },

    updated() {
     this.auto_grow("custom-text-block");
    },


  }

</script>
