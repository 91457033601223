<template>
  <div class="header-radio-select">
    <vs-row>
      <vs-col class="lg:mr-5 w-auto">
        <div
          class="radio-card my-5"
          @click="activate(true)"
          :class="{ active: active_el == true }"
        >
          <vs-row>
            <vs-col
              vs-w="10"
              vs-sm="10"
              vs-lg="10"
              vs-xs="10"
              class="radio-label"
            >
              Default
            </vs-col>
            <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
              <vs-radio v-model="defaultHeader" vs-value="1" vs-name="header">
              </vs-radio>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">

              <span class="radio-info">Use footer from global account settings</span>
            </vs-col>
          </vs-row>
        </div>
      </vs-col>
      <vs-col class="w-auto">
        <div
          class="radio-card my-5"
          @click="activate(false)"
          :class="{ active: active_el == false }"
        >
          <vs-row>
            <vs-col
              vs-w="10"
              vs-sm="10"
              vs-lg="10"
              vs-xs="10"
              class="radio-label"
            >
              Custom
            </vs-col>
            <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
              <vs-radio v-model="defaultHeader" vs-value="0" vs-name="header">
              </vs-radio>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
              <span class="radio-info">Add custom footer information and logo</span>
            </vs-col>
          </vs-row>
        </div>
      </vs-col>
    </vs-row>
    <div :class="{ disabled: headerDisabled == 0 }">
      <vs-row class="mt-8">
        <vs-col vs-w="12" vs-lg="12" vs-sm="12">
          <h4 class="mb-4 font-normal">Logo</h4>
          <hr />
        </vs-col>
        <vs-row>
          <div>
            <vs-row>
              <vs-col vs-w="12" vs-lg="12" vs-sm="12">
                <h5 class="mb-4 mt-10 font-bold"></h5>
              </vs-col>

              <vs-row>
                <p class="text-base mb-4 font-light">
                  Logo will appear at the top of this hosted payment page. Image file should be '.png' format and no larger than {{ maxFileSize }} mb.
                </p>
                <div class="w-full">
                  <div class="vx-col w-full vs-con-loading__container">
                    <div class="flex upload-img my-5" v-if="image">
                      <!-- Image Container -->
                      <div class="flex">
                         <div class="company-logo-wrap relative">
                           <S3ImageGenerator :is-base64="isBase64(image)" :document="image" :custom-class="''" :key="image" />
                            <span class="XCircleIcon">
                              <feather-icon icon="XCircleIcon" class="ml-1" @click="clearImage" :disabled="!headerDisabled" v-if="!defaultHeader" />
                            </span>
                        </div>
                      </div>
                    </div>

                    <input type="file" name="companyLogo" class="hidden" v-validate="{ size: maxFileSize * 1024 }" ref="uploadImgInput" @change="updateCurrImg" accept="image/png" />
                    <div class="flex upload-img my-5 items-center" v-if="!image">
                      <div class="force-btn-width flex flex-col md:flex-row justify-content-start flex-wrap md:flex-nowrap items-center">
                        <vs-button flat v-round class="only-border-btn upicon ml-5" size="medium" type="border" @click="$refs.uploadImgInput.click()" :disabled="!headerDisabled">
                          <upload-icon size="1.5x" class="custom-class"></upload-icon>
                          Upload Image
                        </vs-button>

                        <div class="mx-5 my-2 md:my-0">
                          <span class="text-black text-base">Or</span>
                        </div>
                        <vs-button flat v-round class="only-border-btn upicon" size="medium" type="border" @click="popupActive = true" :disabled="!headerDisabled">
                          <folder-icon size="1.5x" class="custom-class"></folder-icon>
                          Choose From Library
                        </vs-button>
                      </div>
                    </div>
                    <span class="text-danger text-sm" v-show="errors.has('companyLogo')">{{ errors.first("companyLogo") }}</span>
                  </div>
                </div>
              </vs-row>
            </vs-row>
          </div>
        </vs-row>
        <vs-row class="mb-4 mt-8">
          <h4 class="mr-5 mb-4 font-normal">Co-brand logo</h4>

          <vs-switch class="" v-model="headerData.partnerLogoEnabled" @change="partnerToggle" :disabled="!headerDisabled" />
          <vs-col>
            <hr />
          </vs-col>

          <vs-row class="mt-6" v-if="headerData.partnerLogoEnabled">
            <p class="text-base mb-4 font-light">
              Partner logo will appear at the top of this payment page beside the company logo. Image file should be '.png' format and no larger than {{ maxFileSize }} mb.
            </p>
            <div class="w-full">
              <template v-if="partnerimage">
                <div class="flex">
                  <div class="company-logo-wrap relative">
                    <S3ImageGenerator :is-base64="isBase64(partnerimage)" :document="partnerimage" :custom-class="''" :key="partnerimage" />
                    <span class="XCircleIcon">
                      <feather-icon icon="XCircleIcon" class="ml-1" @click="clearPartnerImage" v-if="!defaultHeader" />
                    </span>
                  </div>
                </div>
              </template>
              <input type="file" name="partnerLogo" class="hidden" v-validate="{ size: maxFileSize * 1024 }" ref="uploadPartnerImgInput" @change="updateCurrPartnerImg" accept="image/png" />
              <div class="flex upload-img items-center flex-wrap my-5" v-if="!partnerimage">
                <div class="force-btn-width flex flex-col md:flex-row justify-content-start flex-wrap md:flex-nowrap items-center">
                  <vs-button flat v-round class="only-border-btn upicon ml-5" size="medium" type="border" @click="$refs.uploadPartnerImgInput.click()">
                      <upload-icon size="1.5x" class="custom-class"></upload-icon>Upload Image</vs-button>
                  <div class="mx-5 my-2 md:my-0">
                    <span class="text-black text-base">Or</span>
                  </div>
                  <vs-button flat v-round class="only-border-btn upicon" size="medium" type="border" @click="popupActivePartner = true">
                    <folder-icon size="1.5x" class="custom-class"></folder-icon>
                    Choose From Library
                  </vs-button>
                </div>
              </div>
              <span class="text-danger text-sm" v-show="errors.has('partnerLogo')">{{ errors.first("partnerLogo") }}</span>
            </div>
          </vs-row>
        </vs-row>
      </vs-row>
      <vs-row>
        <div>
          <vs-row class="mt-8">
            <vs-col vs-w="12" vs-lg="12" vs-sm="12">
              <h4 class="mb-4 font-normal">Footer</h4>
              <hr />
              <p class="mt-5 mb-5 text-base font-light">
                Information entered here will appear at the bottom of the payment page when customers make payments on requests sent from this template.
              </p>
            </vs-col>
            <vs-row>
            <div class="input-grp" >
              <div class="mb-5 mt-5">
                <label class="label w-full">Business Name</label>
                <vs-input
                  class="w-full"
                  v-validate="'min:3'"
                  size="large"
                  v-model="headerData.companyName"
                  name="companyName"
                  id="companyName"
                  :disabled="!headerDisabled"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('companyName')"
                  >{{ errors.first("companyName") }}</span
                >
              </div>
            </div>
            <div class="input-grp">
              <div class="mb-5 mt-5">
                <label class="label w-full">ABN</label>
                <br />
                <the-mask
                  :class="
                    headerData.abn
                      ? 'vs-inputx vs-input--input large'
                      : 'vs-inputx vs-input--input large'
                  "
                  :mask="['###########']"
                  v-model="headerData.abn"
                  masked
                  name="abn"
                  id="abn"
                  ref="abn"
                  v-validate.immediate="'abn'"
                  :disabled="!headerDisabled"
                /><br />
                <span class="text-danger text-sm" v-show="errors.has('abn')">{{
                  errors.first("abn")
                }}</span>
              </div>
            </div>
            <div class="input-grp" >
              <div class="mb-5 mt-5">
                <label class="label w-full">Email</label>
                <vs-input
                  class="w-full"
                  v-validate="'email'"
                  size="large"
                  v-model="headerData.companyEmail"
                  name="companyEmail"
                  id="companyEmail"
                  :disabled="!headerDisabled"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('companyEmail')"
                  >{{ errors.first("companyEmail") }}</span
                >
              </div>
            </div>
            <div class="input-grp">
              <div class="mb-5 mt-5">
                <label class="label w-full">Phone</label>
                <br />
                <the-mask
                  :class="
                    headerData.phone
                      ? 'vs-inputx vs-input--input large'
                      : 'vs-inputx vs-input--input large'
                  "
                  :mask="[checkPhoneNumber]"
                  v-model="headerData.phone"
                  masked
                  name="phone"
                  id="phone"
                  ref="phone"
                  v-validate.immediate="'phoneFormat'"
                  :disabled="!headerDisabled"
                /><br />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('phone')"
                  >{{ errors.first("phone") }}</span
                >
              </div>
            </div>
            <div class="input-grp" >
              <div class="mb-5 mt-5">
                <label class="label w-full">Website</label>
                <vs-input
                  class="w-full"
                  v-validate.immediate="'urlFormat'"
                  size="large"
                  v-model="headerData.website"
                  name="website"
                  id="website"
                  :disabled="!headerDisabled"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('website')"
                  >{{ errors.first("website") }}</span
                >
              </div>
            </div>
            <div class="input-grp">
              <div class="mb-5 mt-5">
                <label class="label w-full">Address</label>
                <vs-input
                  class="w-full"
                  size="large"
                  v-model="headerData.address"
                  name="address"
                  :disabled="!headerDisabled"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('address')"
                  >{{ errors.first("address") }}</span
                >
              </div>
            </div>
            </vs-row>
          </vs-row>
        </div>
      </vs-row>
    </div>
    <!-- popup starts -->
    <div class="centerx">
      <vs-popup
        class="holamundo"
        title="Logo Library Merchant"
        :active.sync="popupActive"
      >
        <vs-row>
          <div
            @click="clickLogo(logo)"
            v-for="logo in logos"
            :key="logo.logoId"
          >
            <vs-col vs-w="4" vs-lg="4" vs-sm="12">
<!--              <img class="selectLogo" :src="logo.logoImage" />-->
              <S3ImageGenerator
                :is-base64="false"
                :document="logo.logoImage"
                :custom-class="''"
                :key="logo.logoImage"
              />
            </vs-col>
          </div>
        </vs-row>
      </vs-popup>
    </div>

    <div class="centerx">
      <vs-popup
        class="holamundo"
        title="Logo Library Partner"
        :active.sync="popupActivePartner"
      >
        <vs-row>
          <div
            @click="clickPartnerLogo(logo)"
            v-for="logo in logos"
            :key="logo.logoId"
          >
            <vs-col vs-w="4" vs-lg="4" vs-sm="12">
<!--              <img class="selectLogo" :src="logo.logoImage" />-->
              <S3ImageGenerator
                :is-base64="false"
                :document="logo.logoImage"
                :custom-class="''"
                :key="logo.logoImage"
              />
            </vs-col>
          </div>
        </vs-row>
      </vs-popup>
    </div>
    <!-- popup ends -->
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import { UploadIcon } from "vue-feather-icons";
import { FolderIcon } from "vue-feather-icons";
import { Validator } from "vee-validate";
import S3ImageGenerator from "@/views/components/S3ImageGenerator";

const dictionary = {
  en: {
    attributes: {
      companyName: "company name",
      abn: "abn",
      companyEmail: "company email",
      phone: "phone number",
    },
  },
};
Validator.localize(dictionary);
export default {
  name: "PageHeader",
  props: ["headerData", "logos"],
  components: { TheMask, UploadIcon, FolderIcon, S3ImageGenerator },
  data() {
    return {
      active_el: true,
      headerDisabled: "",
      defaultHeader: true,
      popupActive: false,
      popupActivePartner: false,
      partnerLogo: "",
      imageUrl: `${process.env.VUE_APP_API_URL}uploads/merchantlogo/`,
      pageHeader: {},
      image: "",
      partnerimage: "",
      maxFileSize: process.env.VUE_APP_FILE_MAX_SIZE_MB,
    };
  },
  mounted() {
    this.pageHeader = this.headerData;
    if (this.headerData.image) {
      this.image = this.headerData.image;
    }
    if (this.headerData.partnerimage) {
      this.partnerimage = this.headerData.partnerimage;
    }
    this.active_el = this.headerData.isDefaultHeader;
    this.defaultHeader = this.headerData.isDefaultHeader;
    if(this.defaultHeader == false){
      this.headerDisabled = 1;
    }else{
      this.headerDisabled = 0;
    }
  },
  computed: {
    checkPhoneNumber(){
      let mask = '#### ### ###';
      if(this.headerData.phone && this.headerData.phone.length > 0){
        let numLength = this.headerData.phone.length;
        let value1 = '';
        let value2 = '';
        if(numLength >= 2){
          value1 = this.headerData.phone.substring(0,2);
          if(value1 === '13'){
            value2 = this.headerData.phone.substring(0,4);
          }
        }
        if(value1 === '02' || value1 === '03' || value1 === '07' || value1 === '08'){
          mask = "## #### ####"
        }else{
          mask = "#### ### ###"
        }

        if(value1 === '13'){
          if(parseInt(value2) >= 1300 || value2 === '130' || value2 === '13 0'){
            mask = "#### ### ###"
          }else{
            mask = "## ## ##"
          }
        }
      }
      return mask;
    }
  },
  watch: {
    headerData: function (val) {
      if (val.image) {
        if(val.image.match(/data:image/)){
          
          this.image = val.image;
        }else{
          this.image = val.image;
        }
      } else {
        this.image = "";
      }
      if (val.partnerimage) {
        if(val.partnerimage.match(/data:image/)){
          this.partnerimage = val.partnerimage;
        }else{
          this.partnerimage = val.partnerimage;
        }
      } else {
        this.partnerimage = "";
      }
    },
    isFormDirty(val) {
      if(val){
        this.$emit("checkDirty",this.isFormDirty)
      }
    }
  },
  methods: {
    activate(el) {
      this.active_el = el;
      this.defaultHeader = el;
      if (this.defaultHeader == false) {
        this.headerDisabled = 1; //custom header
      } else {
        this.headerDisabled = 0; //company default header
      }
      this.$emit("changeHeader", el);
    },
    clickLogo(logo) {
      this.headerData.image = logo.logoImage;
      this.headerData.companyLogo = logo._id;
      this.image = this.headerData.image;
      this.popupActive = false;
      this.$emit("checkDirty",true)
    },
    clickPartnerLogo(logo) {
      this.headerData.partnerimage = logo.logoImage;
      this.headerData.partnerLogo = logo._id;
      this.partnerimage = this.headerData.partnerimage;
      this.popupActivePartner = false;
      this.$emit("checkDirty",true)
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.headerData.companyLogo = input.target.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.headerData.image = e.target.result;
          this.image = this.headerData.image;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    updateCurrPartnerImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.headerData.partnerLogo = input.target.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.headerData.partnerimage = e.target.result;
          this.partnerimage = this.headerData.partnerimage;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    clearImage() {
      this.headerData.image = null;
      this.headerData.companyLogo = null;
      this.image = null;
      this.$emit("checkDirty",true);
      this.$refs.uploadImgInput.value = "";
      if (this.errors.has(`companyLogo`)) {
        this.errors.remove(`companyLogo`);
      }
    },
    clearPartnerImage() {
      this.headerData.partnerimage = null;
      this.headerData.partnerLogo = null;
      this.partnerimage = null;
      this.$emit("checkDirty",true);
      this.$refs.uploadPartnerImgInput.value = "";
      if (this.errors.has(`partnerLogo`)) {
        this.errors.remove(`partnerLogo`);
      }
    },
    partnerToggle(){
      this.$emit("checkToggle");
    },
    isBase64(key) {
      if ( typeof key !== 'string' ) return false;
      const validation = new RegExp(/data:image/);

      return validation.test(key)
    },
  },
  created() {
    this.$validator.extend("urlFormat", {
      getMessage: () =>
        "The website field is not a valid url.",
      validate: value =>
        value.match(
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
        ) !== null
    });
    this.$validator.extend("phoneFormat", {
      getMessage: () =>
        "The phone number format is invalid.",
      validate: value =>
        value.match(
          /^(^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^1300[\s.-]\d{3}[\s.-]\d{3})|(^1800[\s.-]\d{3}[\s.-]\d{3})|(^13[\s.-]\d{2}[\s.-]\d{2}$)|(^02[\s.-]\d{4}[\s.-]\d{4})|(^03[\s.-]\d{4}[\s.-]\d{4})|(^07[\s.-]\d{4}[\s.-]\d{4})|(^08[\s.-]\d{4}[\s.-]\d{4})/
        ) !== null
    });
    this.$validator.extend("abn", {
      getMessage: () => "The abn field is invalid.",
      validate: (value) => value.match(/^\d{11}$/g) !== null,
    });

  }
};
</script>