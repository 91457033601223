<template>
  <vx-card class="accordin-card mt-5">
    <vs-collapse accordion>
      <vs-collapse-item open ref="groupCollapse">
        <div slot="header">
          <h3 class="text-left card-title">Group access <span>*</span></h3>
        </div>
        <p>
          You can enable custom form fields to collect information related to
          this payment request.
        </p>

        <vs-row class="m-3">
          <vs-select
            placeholder="Choose groups"
            multiple
            autocomplete
            class="selectExample"
            v-model="selectedGroups"
            @change="checkSelected"
          >
            <div class="selectAllDiv">
              <a class="dropdownSelectAllButton" @click="selectAllGroup">{{
                !allGroupsSelected ? `Select all` : `Unselect all`
              }}</a>
            </div>

            <vs-select-item
              :key="index"
              :value="group"
              :text="group.groupName"
              v-for="(group, index) in groupData"
            />
          </vs-select>
        </vs-row>

            <vs-row class="mt-4">
              <vs-card
                v-for="(group, indextr) in selectedGroups"
                :key="indextr"
                class="permissionCard"
              >
                <span class="element">
                  <a @click="removeGroup(indextr)">
                    <x-icon size="1.5x" class="custom-class"></x-icon></a
                ></span>

                <vs-row
                  ><h3 class="font-medium text-lg mb-5">
                    {{ group.groupName }}
                  </h3>
                </vs-row>
                <vs-row><p class="mb-3 text-base">Access type:</p> </vs-row>

                <vs-row class="center">
                  <div v-on:click="selectViewOnlyOrRefund('viewOnly', indextr)">
                    <vs-card
                      class="mr-4 accessTypeCard"
                      :class="{
                        accessTypeCardActive:
                          selectedGroups[indextr].useThemeViewOnly,
                      }"
                    >
                      <vs-row class="center mb-2">
                        <eye-icon size="1.5x" class="custom-class"></eye-icon>
                      </vs-row>
                      <vs-row class="center">
                        <p class="text-xs">View Only</p>
                      </vs-row>
                    </vs-card>
                  </div>

                  <div
                    v-on:click="
                      selectViewOnlyOrRefund('viewAndRefund', indextr)
                    "
                  >
                    <vs-card
                      class="ml-4 accessTypeCard"
                      :class="{
                        accessTypeCardActive:
                          selectedGroups[indextr].useThemeViewAndRefund,
                      }"
                    >
                      <vs-row class="center mb-2">
                        <users-icon
                          size="1.5x"
                          class="custom-class"
                        ></users-icon>
                      </vs-row>
                      <vs-row class="center">
                        <p class="text-xs">View & Refund</p>
                      </vs-row>
                    </vs-card>
                  </div>
                </vs-row>

                <hr />
                <vs-row
                  class="mt-4"
                  v-if="selectedGroups[indextr].showViewOnlyMsg"
                >
                  <p class="text-center permissionMsg">
                    Users can view all transactions related to this hosted
                    payment page, but can’t refund
                  </p>
                </vs-row>
                <vs-row
                  class="mt-4"
                  v-if="selectedGroups[indextr].showViewAndRefundMsg"
                >
                  <p class="text-center permissionMsg">
                    Users can view and refund all transactions related to this
                    hosted payment page
                  </p>
                </vs-row>
              </vs-card>
            </vs-row>

            <delete-popup :popup-active="popupDelActive" @handleDeleteClick="delActions"></delete-popup>
      </vs-collapse-item>
    </vs-collapse>
  </vx-card>
</template>
<script>
import { UserIcon, UsersIcon, EyeIcon, XIcon } from "vue-feather-icons";
import DeletePopup  from '@/views/components/DeletePopup'
export default {
  props: ["groupData","selectedGroupData"],
  components: {UserIcon, UsersIcon, XIcon, DeletePopup, EyeIcon},
  data() {
    return {
      allGroupsSelected: false,
      selectedGroups: [],
      popupDelActive: false,
      delIndex: ""
    };
  },
  mounted() {
    if(this.selectedGroupData.length>0){
      this.selectedGroups = [...this.selectedGroupData];
    }
  },
  methods: {
    // ! Functions for PR template permitions start here
    selectAllGroup() {
      this.allGroupsSelected = !this.allGroupsSelected;
      if (this.allGroupsSelected) {
       
        this.selectedGroups = [...this.groupData];
      } else {
        this.selectedGroups = [];
      }
      this.emitSelected();
    },
    selectAccessType(el, index) {
      this.selectedGroups[index].accessType = el;
      if (el == "individual") {
        this.selectedGroups[index].viewOnly = false;
        this.selectedGroups[index].useThemeIndividual = true;
        this.selectedGroups[index].useThemeGroup = false;
        this.selectedGroups[index].disableViewOnly = true;
        this.selectedGroups[index].showIndividualMsg = true;
        this.selectedGroups[index].showGroupEditableMsg = false;
        this.selectedGroups[index].showGroupViewOnlyMsg = false;
      }
      if (el == "group") {
        this.selectedGroups[index].useThemeGroup = true;
        this.selectedGroups[index].useThemeIndividual = false;
        this.selectedGroups[index].disableViewOnly = false;
        this.selectedGroups[index].showIndividualMsg = false;
        this.selectedGroups[index].showGroupEditableMsg = true;
        this.selectedGroups[index].showGroupViewOnlyMsg = false;
      }
      this.emitSelected();
    },
    checkSelected(){
      if(this.selectedGroups.length == this.groupData.length){
        this.allGroupsSelected = true;
      }else{
        this.allGroupsSelected = false;
      }
     
      this.$refs.groupCollapse.maxHeight = "none !important";
      this.emitSelected();
    },
    emitSelected(){
      
      this.$emit('selectedGroups',this.selectedGroups);
    },
    //need to fire this method if you want to implement delete popup in component
    delGroup(index){
      this.popupDelActive = true;
      this.delIndex = index;
    },
    removeGroup(index){
      //change back the index data to  default state
      this.selectedGroups[index].viewOnly = false;
        this.selectedGroups[index].viewAndRefund = false;
        this.selectedGroups[index].showViewOnlyMsg = false;
        this.selectedGroups[index].showViewAndRefundMsg = false;
        this.selectedGroups[index].useThemeViewOnly = false;
        this.selectedGroups[index].useThemeViewAndRefund = false;

      this.selectedGroups.splice(index,1)
      this.emitSelected();
    },
    selectViewOnlyOrRefund(el, index) {
      if (el == "viewOnly") {
        this.selectedGroups[index].viewOnly = true;
        this.selectedGroups[index].viewAndRefund = false;
        this.selectedGroups[index].showViewOnlyMsg = true;
        this.selectedGroups[index].showViewAndRefundMsg = false;
        this.selectedGroups[index].useThemeViewOnly = true;
        this.selectedGroups[index].useThemeViewAndRefund = false;
      }
      if (el == "viewAndRefund") {
        this.selectedGroups[index].viewOnly = false;
        this.selectedGroups[index].viewAndRefund = true;
        this.selectedGroups[index].showViewOnlyMsg = false;
        this.selectedGroups[index].showViewAndRefundMsg = true;
        this.selectedGroups[index].useThemeViewOnly = false;
        this.selectedGroups[index].useThemeViewAndRefund = true;
      }
      
      this.emitSelected();
    },
    delActions (event) {
      switch (event) {
        case 'delete':
          this.popupDelActive = false;
          this.removeGroup(this.delIndex);
          break;
        case 'cancel':
          this.popupDelActive = false;
          this.delIndex = "";
          break;
        default:
          this.popupDelActive = false;
          this.delIndex = "";
      }
    },
  },
};
</script>